
export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },
    {
        title: 'Leave Date',
        key: 'LeaveDateStart',
        minWidth: 80,
		sortable: true,
    },
    {
        title: 'Leave Type',
        key: 'LeaveType',
        // sortable: true,
        minWidth: 80,
    },

	{
        title: 'Start Time',
        key: 'SLStartTime',
		// sortable: true,
        minWidth: 80,
    },

    {
        title: 'End Time',
        key: 'SLEndTime',
		// sortable: true,
        minWidth: 80,
    },
    {
        title: 'Status',
        key: 'LeaveStatus',
        minWidth: 80,
		sortable: true,
    },
    {
        title: 'Note',
        key: 'Reason',
        minWidth: 80,
		// sortable: true,
    },


]
